import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import PrivateRoute from './privateRoutes';
import { ProgressSpinner } from 'primereact/progressspinner';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase/firebaseInit';
const Merchandisers = lazy(() => import('../screens/merchandiser/merchandiser'));
const Stores = lazy(() => import('../screens/store/store'));
const Invoices = lazy(() => import('../screens/invoices/invoices'));
const Dashboard = lazy(() => import('../screens/dashboard/dashboard'));
const Login = lazy(() => import('../screens/login/login'));
const cache = new InMemoryCache();

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, response }: any) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }: any) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
  if (operation) console.log(`[Oper]: ${operation}`);
  if (response) console.log(`[Res]: ${response}`);
  if (networkError?.statusCode === 401) {
    localStorage.clear();
    window.indexedDB.deleteDatabase('firebase-heartbeat-store');
    window.indexedDB.deleteDatabase('firebaseLocalStorageDb');
    signOut(auth);
  }
});

const httpLinkTask = createHttpLink({
  uri: process.env['NX_GRAPHQL_URL'] + '/task'
});

const httpLinkUser = createHttpLink({
  uri: process.env['NX_GRAPHQL_URL'] + '/user'
});

export const taskClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLinkTask)]),
  cache
});

export const userClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLinkUser)]),
  cache
});

const Loader = (
  <div className="w100p h100p flex justify-center align-items-center">
    <ProgressSpinner />
  </div>
);

function routes(): JSX.Element {
  return (
    <Routes>
      <Route path="" element={<Navigate to="dashboard" />} />
      <Route
        path="login"
        element={
          <Suspense fallback={Loader}>
            <Login />
          </Suspense>
        }></Route>
      <Route
        path="dashboard"
        element={
          <Suspense fallback={Loader}>
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          </Suspense>
        }></Route>
      <Route path="merchandisers">
        <Route path="" element={<Navigate to="0" />} />
        <Route path=":id">
          <Route
            path=""
            element={
              <Suspense fallback={Loader}>
                <PrivateRoute>
                  <Merchandisers />
                </PrivateRoute>
              </Suspense>
            }></Route>
          <Route path="view-details" element={<Navigate to="/merchandisers" />} />
          <Route
            path="view-details/:merchId"
            element={
              <Suspense fallback={Loader}>
                <PrivateRoute>
                  <Merchandisers />
                </PrivateRoute>
              </Suspense>
            }></Route>
        </Route>
      </Route>

      <Route path="stores">
        <Route path="" element={<Navigate to="0" />} />
        <Route path=":id">
          <Route
            path=""
            element={
              <Suspense fallback={Loader}>
                <PrivateRoute>
                  <Stores />
                </PrivateRoute>
              </Suspense>
            }></Route>
          <Route path="view-details" element={<Navigate to="/stores" />} />

          <Route
            path="view-details/:storeId"
            element={
              <Suspense fallback={Loader}>
                <PrivateRoute>
                  <Stores />
                </PrivateRoute>
              </Suspense>
            }></Route>
        </Route>
      </Route>

      <Route path="invoices">
        <Route path="" element={<Navigate to="0" />} />
        <Route path=":id">
          <Route
            path=""
            element={
              <Suspense fallback={Loader}>
                <PrivateRoute>
                  <Invoices />
                </PrivateRoute>
              </Suspense>
            }></Route>
          <Route path="view-details" element={<Navigate to="/invoices" />} />
          <Route
            path="view-details/:detailsId/:subIdOne/:subIdTwo"
            element={
              <Suspense fallback={Loader}>
                <PrivateRoute>
                  <Invoices />
                </PrivateRoute>
              </Suspense>
            }></Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default routes;
