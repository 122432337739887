import { signOut } from 'firebase/auth';
import { Navigate, useNavigate } from 'react-router-dom';
import auth from '../firebase/firebaseInit';
function parseJwt(resToken: any) {
  const token = resToken;
  if (token?.length && token !== 'null') {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
    if (base64) {
      const jsonPayload = decodeURIComponent(
        atob(base64)
          ?.split('')
          ?.map((c) => {
            return '%' + ('00' + c?.charCodeAt(0)?.toString(16))?.slice(-2);
          })
          ?.join('')
      );
      return JSON.parse(jsonPayload);
    } else {
      localStorage.clear();
      return false;
    }
  } else {
    return false;
  }
}

function getTokenExpirationDate(expDate: any): any {
  if (expDate === undefined) return null;
  const date = new Date(0);
  date.setUTCSeconds(expDate);
  return date;
}

function isTokenExpired(tokendata: any): boolean {
  if (tokendata) {
    const date: Date = getTokenExpirationDate(tokendata.exp);
    if (date === undefined) return false;
    const expiry = !(date.valueOf() > new Date().valueOf());
    if (expiry) {
      localStorage.clear();
      window.indexedDB.deleteDatabase('firebase-heartbeat-store');
      window.indexedDB.deleteDatabase('firebaseLocalStorageDb');
      signOut(auth);
    }
    return expiry;
  } else {
    return true;
  }
}

export default function PrivateRoute({ children }: any) {
  const tokendata = parseJwt(localStorage.getItem('token'));
  const tokenStatus = isTokenExpired(tokendata);
  if (tokenStatus) {
    return <Navigate to="/login" />;
  }
  return children;
}
