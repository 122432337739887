import { Toast } from 'primereact/toast';
import { AdminContext } from './context/admin-context';
import Routes from './routes/routes';
import './app.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import Sidebar from './screens/components/sidebar/sidebar';
import { useLocation, useNavigate } from 'react-router-dom';

export function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const toast: any = useRef(null);
  const context: any = useContext(AdminContext);
  const setAdminState = context?.['setAdminData'];

  const [sidebarState, setSidebarState] = useState(false);
  // toast.current.show({ severity: type, summary: header, detail: message, life: 3000 })
  useEffect(() => {
    setAdminState({
      toast: toast.current
    });
    setSidebarState(false);
  }, []);

  return (
    <div className="app-container w100p">
      <div className={`${location.pathname === '/login' ? '' : 'header h64 w100p flex align-items-center'}`}>
        {location.pathname !== '/login' && (
          <img
            className="ml20 pointer"
            width={20}
            height={20}
            src={sidebarState ? 'assets/icons/cross.svg' : 'assets/icons/hamburger.svg'}
            alt=""
            onClick={() => setSidebarState(!sidebarState)}
            automation-id="app-hamburger-icon"
          />
        )}

        <div className={`${location.pathname === '/login' ? 'none' : 'flex'}`}>
          <span>
            <img
              className=" ml20 mt1"
              src="/assets/icons/admin-logo.svg"
              alt="admin-logo"
              width={39}
              height={36}
              automation-id="store-merch-icon"
              onClick={() => navigate('/dashboard')}
            />
          </span>
          <div className="text-primary f24 flex ml13 pt2" onClick={() => navigate('/dashboard')}>
            MobileMerch Solutions
          </div>
        </div>
      </div>
      <div className={` ${location.pathname === '/login' ? 'flex-1 h100p' : 'app-body flex-1'}`}>
        {location.pathname !== '/login' && (
          <div
            className="sidebar-nav"
            onMouseEnter={() => setSidebarState(true)}
            onMouseLeave={() => setSidebarState(false)}
            onClick={() => setSidebarState(false)}>
            <Sidebar sidebarState={sidebarState} setSidebarState={(e: boolean) => setSidebarState(e)} />
          </div>
        )}

        <Routes />
      </div>
      <Toast ref={toast} />
    </div>
  );
}

export default App;
