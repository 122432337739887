import './sidebar.scss';
import { Sidebar as PSidebar } from 'primereact/sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import auth from '../../../firebase/firebaseInit';

/* eslint-disable-next-line */
export interface SidebarProps {
  sidebarState: boolean;
  setSidebarState: (e: boolean) => void;
}

export function Sidebar(props: SidebarProps) {
  const location = useLocation();

  useEffect(() => {
    props?.setSidebarState(false);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.indexedDB.deleteDatabase('firebase-heartbeat-store');
    window.indexedDB.deleteDatabase('firebaseLocalStorageDb');
    signOut(auth);
  };

  return (
    <PSidebar
      className={`${props?.sidebarState ? 'full-width' : 'normal-width'} fit-content`}
      visible={true}
      onHide={() => null}
      showCloseIcon={false}
      modal={false}
      appendTo={'self'}>
      <NavLink to="dashboard" className={`${location.pathname === '/dashboard' ? 'active link' : 'link'} mb15`}>
        <img width={20} height={20} src="assets/icons/dashboard.svg" alt="" automation-id="sidebar-dashboard-icon" />
        <div
          style={{ display: props?.sidebarState ? 'block' : 'none' }}
          className="link_text"
          automation-id="sidebar-dashboard-link">
          Dashboard
        </div>
      </NavLink>
      <NavLink to="invoices" className={location.pathname === '/invoices' ? 'active link ' : 'link'}>
        <img width={20} height={20} src="assets/icons/invoice.svg" alt="" automation-id="sidebar-invoice-icon" />
        <div
          style={{ display: props?.sidebarState ? 'block' : 'none' }}
          className="link_text"
          automation-id="sidebar-invoices-link">
          Invoices
        </div>
      </NavLink>
      {props?.sidebarState && (
        <div className="flex-col mb15 sub-nav">
          <NavLink className="block ml60 mb2" to="invoices/0">
            <div
              style={{ display: props?.sidebarState ? 'block' : 'none' }}
              className="f15"
              automation-id="sidebar-invoices-stores-link">
              Stores
            </div>
          </NavLink>
          <NavLink className="block ml60 mb2" to="invoices/1">
            <div
              style={{ display: props?.sidebarState ? 'block' : 'none' }}
              className="f15"
              automation-id="sidebar-invoices-merch-link">
              Merchandisers
            </div>
          </NavLink>
        </div>
      )}
      <NavLink to="merchandisers" className={location.pathname === '/merchadsiers' ? 'active link ' : 'link'}>
        <img width={20} height={20} src="assets/icons/merchandiserIcon.svg" alt="" automation-id="sidebar-merch-icon" />
        <div
          style={{ display: props?.sidebarState ? 'block' : 'none' }}
          className="link_text"
          automation-id="sidebar-merch-link">
          Merchandisers
        </div>
      </NavLink>
      {props?.sidebarState && (
        <div className="flex-col mb15 sub-nav">
          <NavLink to="merchandisers/0" className="block ml60 mb2">
            <div
              style={{ display: props?.sidebarState ? 'block' : 'none' }}
              className="f15"
              automation-id="sidebar-merch-verfication-link">
              Verifications
            </div>
          </NavLink>
          <NavLink to="merchandisers/1" className="block ml60 mb2">
            <div
              style={{ display: props?.sidebarState ? 'block' : 'none' }}
              className="f15"
              automation-id="sidebar-merch-list-link">
              List
            </div>
          </NavLink>
        </div>
      )}
      <NavLink to="stores" className={location.pathname === '/stores' ? 'active link ' : 'link'}>
        <img width={20} height={20} src="assets/icons/storeIcon.svg" alt="" automation-id="sidebar-store-icon" />
        <div
          style={{ display: props?.sidebarState ? 'block' : 'none' }}
          className="link_text"
          automation-id="sidebar-stores-link">
          Stores
        </div>
      </NavLink>
      {props?.sidebarState && (
        <div className="flex-col mb15 sub-nav">
          <NavLink className="block ml60 mb2" to="stores/0">
            <div
              style={{ display: props?.sidebarState ? 'block' : 'none' }}
              className="f15"
              automation-id="sidebar-stores-verification-link">
              Verifications
            </div>
          </NavLink>
          <NavLink className="block ml60 mb2" to="stores/1">
            <div
              style={{ display: props?.sidebarState ? 'block' : 'none' }}
              className="f15"
              automation-id="sidebar-stores-list-link">
              List
            </div>
          </NavLink>
          <NavLink className="block ml60 mb2" to="stores/2">
            <div
              style={{ display: props?.sidebarState ? 'block' : 'none' }}
              className="f15"
              automation-id="sidebar-stores-report-link">
              Reports
            </div>
          </NavLink>
          <NavLink className="block ml60 mb2" to="stores/3">
            <div
              style={{ display: props?.sidebarState ? 'block' : 'none' }}
              className="f15"
              automation-id="sidebar-stores-invitation-link">
              Invitations
            </div>
          </NavLink>
        </div>
      )}
      <div className="h100p logout">
        <NavLink to="" className={location.pathname === '' ? 'active link mr10' : 'link mr10'} onClick={handleLogout}>
          <img width={20} height={20} src="assets/icons/logout.svg" alt="" automation-id="sidebar-logout-icon" />
          <div
            style={{ display: props?.sidebarState ? 'block' : 'none' }}
            className="link_text"
            automation-id="sidebar-logout-link">
            Logout
          </div>
        </NavLink>
      </div>
    </PSidebar>
  );
}

export default Sidebar;
