import { createContext, useState } from 'react';

export const AdminContext: any = createContext({});

const AdminContextProvdier = AdminContext.Provider;

export function AdminContextAPI(props: any) {
  const [adminData, setAdminData] = useState({});

  return <AdminContextProvdier value={{ adminData, setAdminData }}>{props?.children}</AdminContextProvdier>;
}
